import React from "react"
import styled, { css } from "styled-components"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ArrowLeft from "../../images/arrow_left.svg"
import ArrowRight from "../../images/arrow_right.svg"

const SliderUnderImage = styled.div`
  display: none;
  text-align: center;
`

const SliderWrapper = styled.div``

const SliderTitle = styled.div`
  color: ${(props) => props.theme.color.blue};
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 22px;
  position: relative;
`

const SliderSubTitle = styled.div`
  position: relative;
  margin-bottom: 15px;
  color: ${(props) => props.theme.color.primary};
  font-weight: 600;
  width: 80vw;
  left: calc(-40vw + 50%);

  @media (min-width: ${(props) => props.theme.sizes_min.lg}) {
    width: 24vw;
    left: calc(-12vw + 50%);
  }
`

// const SliderDescription = styled.p`
//   font-size: 16px;
//   margin-bottom: 0;
//   position: relative;
//   width: 80vw;
//   left: calc(-40vw + 50%);
//   height: 11rem;

//   @media (min-width: ${(props) => props.theme.sizes_min.md}) {
//     width: 28vw;
//     left: calc(-14vw + 50%);
//   }

//   @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
//     width: 30vw;
//     left: calc(-15vw + 50%);
//   }
// `
const SliderItem = styled.div`
  padding: 0 5px;
  max-width: 100%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    padding: 0 20px;
  }
`
const activeItem = css`
  img {
    filter: none;
  }

  ${SliderUnderImage} {
    visibility: visible;
    display: block;
    transition: all 0.2s ease-in-out;
  }
`
const CarouselCustom = styled(Carousel)`
  img {
    margin-bottom: 15px;
    max-width: 100%;
    filter: grayscale(100%);
    transition: all 0.5s ease-in-out;
  }

  .react-multi-carousel-item {
    align-items: flex-start;
  }

  .react-multiple-carousel__arrow {
    bottom: 50px;
    /* @media (min-width: ${(props) => props.theme.sizes_min.sm}) {
      bottom: 30%;
    }
    @media (min-width: ${(props) => props.theme.sizes_min.xl}) {
      bottom: 20%;
    } */

    &--left,
    &--right {
      right: calc(0% + 1px);
      &:before {
        content: "";
        background-size: contain;
        width: 88px;
        height: 46px;
      }

      @media (min-width: ${(props) => props.theme.sizes_min.md}) {
        right: 200px;
      }
    }

    &--left {
      left: calc(0% + 1px);
      &:before {
        background: url(${ArrowLeft}) no-repeat;
      }
      @media (min-width: ${(props) => props.theme.sizes_min.md}) {
        left: 200px;
      }
    }

    &--right {
      right: calc(0% + 1px);
      &:before {
        background: url(${ArrowRight}) no-repeat;
      }
      @media (min-width: ${(props) => props.theme.sizes_min.md}) {
        right: 200px;
      }
    }

    &,
    &:hover,
    &:focus {
      background: transparent;
    }
  }

  /* stylelint-disable selector-max-compound-selectors, selector-max-class */
  .react-multi-carousel-item--active + .react-multi-carousel-item--active {
    ${activeItem}
  }

  .react-multi-carousel-item--active
    + .react-multi-carousel-item--active
    + .react-multi-carousel-item--active {
    img {
      filter: grayscale(100%);
    }

    ${SliderUnderImage} {
      display: none;
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
  @media (max-width: 898px) {
    .react-multi-carousel-item--active {
      ${activeItem}
    }
  }
`

const JobsSlider = (props) => {
  return (
    <SliderWrapper
      style={{
        padding: "60px 0",
        ...props.style,
      }}
    >
      {props.title && (
        <div className={"text-center"}>
          <h2 className={"d-inline-block"}>{props.title}</h2>
        </div>
      )}
      <CarouselCustom
        ssr={true}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={5000}
        autoPlay={false}
        centerMode
        draggable={false}
        focusOnSelect
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1380,
            },
            items: 3,
          },
          mobile: {
            breakpoint: {
              max: 898,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1379,
              min: 899,
            },
            items: 3,
          },
        }}
        showDots={false}
        slidesToSlide={1}
        swipeable
      >
        {props.data.map((item) => {
          return (
            <SliderItem key={item.id}>
              <img src={item.imageSrc} alt={item.name} />
              <SliderUnderImage>
                <SliderTitle>{item.name}</SliderTitle>
                <SliderSubTitle>{item.position}</SliderSubTitle>
              </SliderUnderImage>
            </SliderItem>
          )
        })}
      </CarouselCustom>
    </SliderWrapper>
  )
}

export default JobsSlider
