import BenefitParking from "../images/benefits/benefit-parking.svg"
import BenefitFood from "../images/benefits/benefit-food.svg"
import BenefitCoffe from "../images/benefits/benefit-coffee.svg"
import BenefitEquipment from "../images/benefits/benefit-equipment.svg"
import BenefitTelephone from "../images/benefits/benefit-telephone.svg"
import BenefitSickday from "../images/benefits/benefit-sickday.svg"
import BenefitSelfStudy from "../images/benefits/benefit-selfstudy.svg"
import BenefitMealTicket from "../images/benefits/benefit-meal-ticket.svg"
import BenefitEnglish from "../images/benefits/benefit-english.svg"
import BenefitMultisport from "../images/benefits/benefit-multisport.svg"
import BenefitQuietRoom from "../images/benefits/benefit-quiet-room.svg"

export const BENEFITS = [
  {
    heading: "Plná lednice",
    src: BenefitFood,
    text: "Už nikdy nebudeš hladem. Ale pozor, piškotíky jdou na dračku.",
  },
  {
    heading: "Skvělá káva",
    src: BenefitCoffe,
    text: `Sraz u\u00A0kávovaru je náš rituál. Milovníci espressa i\u00A0filtru si přijdou na své.`,
  },
  {
    heading: "Vybavení",
    src: BenefitEquipment,
    text: "Čekej dva monitory, pořádnou židli, notes a\u00A0sdílej polohovací stůl.",
  },
  {
    heading: "Parkování",
    src: BenefitParking,
    text: "Zaparkuj v\u00A0našem areálu jen pár kroků od své židle v\u00A0kanclu.",
  },
  {
    heading: "Sickdays",
    src: BenefitSickday,
    text: "Nepřijdeš do práce a\u00A0my se tě nebudeme ptát proč.",
  },
  {
    heading: "Firemní tarif",
    src: BenefitTelephone,
    text: "Po roce smartfoun s\u00A0neomezenkou. Nebo hned, když ho potřebuješ pro práci.",
  },
  {
    heading: "Sebevzdělávání",
    src: BenefitSelfStudy,
    text: "Osm hodin měsíčně z\u00A0pracovní doby na sebevzdělávání.",
  },
  {
    heading: "Příspěvek na obědy",
    src: BenefitMealTicket,
    text: "Žádné stravenky, 100Kč za každý pracovní den přímo na účet",
  },
  {
    heading: "MultiSport karta",
    src: BenefitMultisport,
    text: "Sportuj až 365 dní v\u00A0roce, my ti přidáme půlku.",
  },
  {
    heading: "Angličtina",
    src: BenefitEnglish,
    text: "Najdi si školu nebo lektora a\u00A0my ti polovinu přispějeme.",
  },
  {
    heading: "Tichá místnost",
    src: BenefitQuietRoom,
    text: "Využij klid na soustředění a\u00A0hluk nech za dveřmi.",
  },
]
